import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import FullCalendar from "@fullcalendar/react"; // must go before plugins

import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useMsal } from "@azure/msal-react";

// mui imports
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";

// local imports
import CalendarEntry from "../../forms/calendarEntry";
import EditCalendarEntry from "../../forms/editCalendarEntry";
import Notifications from "./notifications";
import Search from "./search";
import { DirectoryContext } from "../../App";
import EditBirthdayModal from "../../forms/editBirthdayModal";

const XLSX = require("xlsx");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "90%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "8px",
  maxWidth: "600px",
  p: 2,
  maxHeight: "90vh",
  overflowY: "auto",
};

const types = ["WFH", "Vacation", "Travelling", "Birthday / Anniversary"];

export const EventsContext = createContext({
  calendarEvents: [],
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function NewCalendar() {
  const directoryContext = useContext(DirectoryContext);
  const [employees, setEmployees] = useState(directoryContext.employees);
  const { accounts } = useMsal();
  const user = accounts[0];
  const [data, setData] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [filter, setFilter] = useState("All");
  const [open, setOpen] = useState(false);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [activeEntry, setActiveEntry] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [month, setMonth] = useState("");
  const [admin, setAdmin] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [value, setValue] = useState(0);
  const [mobileView, setMobileView] = useState("dayGridMonth");

  useEffect(() => {
    setEmployees(directoryContext.employees);
  }, [directoryContext.employees]);

  const updateMonth = (e) => {
    console.log(e.view.title);
    setMonth(e.view.title.split(" ")[0]);
    setYear(e.view.title.split(" ")[1]);
  };

  const adminEmails = [
    "carters@transblue.org",
    "jim.wescott@transblue.org",
    "jim.wescott@evergreenbrands.com",
    "glenn.reed@evergreenbrands.com",
    "david.vetter@evergreenbrands.com",
    "vince.ortiz@evergreenbrands.com",
    "jonathan.mcquade@evergreenbrands.com",
  ];

  useEffect(() => {
    console.log(user);
    if (adminEmails.includes(user.username.toLowerCase())) {
      setAdmin(true);
    }
  }, []);

  useEffect(() => {
    let newArr = [];
    data.forEach((entry) => {
      let obj = {
        ...entry,
        title: `${entry.employee} ${entry.type}`,
        extendedProps: {
          azureId: entry.id,
        },
        allDay: true,
      };

      // handle employees from employee database
      // get their birthday
      if (!entry.type) {
        const birthday = new Date(entry.birthday);
        birthday.setFullYear(year);
        const hireDate = new Date(entry.hireDate);

        const today = new Date();
        today.setFullYear(today.getFullYear() + 1);
        today.setMonth(1);
        today.setDate(1);

        // only include employees who have been with the company for more than a year
        if (today.getTime() > hireDate.getTime() + 1000 * 60 * 60 * 24 * 365) {
          hireDate.setFullYear(year);
          newArr.push({
            ...obj,
            id: uuidv4(),
            date: hireDate,
            title: `${entry.displayName} Anniversary`,
            borderColor: "#800080",
            backgroundColor: "#8000801a",
            textColor: "#800080 ",
          });
        }

        hireDate.setFullYear(year);

        newArr.push({
          ...obj,
          id: uuidv4(),
          date: birthday,
          title: `${entry.displayName} Birthday`,
          borderColor: "#0000FF",
          backgroundColor: "#0000FF1a",
          textColor: "#0000FF",
        });
      } else if (entry.type === "Travelling" || entry.type === "Vacation") {
        const end = new Date(
          entry.vacationDates?.[1] || entry.travelDates?.[1]
        );
        end.setDate(end.getDate() + 1);

        newArr.push({
          ...obj,
          start: entry.vacationDates?.[0] || entry.travelDates?.[0],
          end: new Date(end),

          backgroundColor: "#ed6a221a",
          borderColor: "#ed6a22",
          textColor: "#ed6a22",
        });
      } else if (entry.type === "WFH") {
        newArr.push({
          ...obj,
          recurring: entry?.wfh?.recurring,
          date: entry?.wfh?.date,
          daysOfWeek:
            entry?.wfh?.daysOfWeek?.length > 0 ? entry.wfh.daysOfWeek : null,
          backgroundColor: "#00bf001a",
          borderColor: "#00bf00",
          textColor: "#00bf00",
        });
      }
    });

    setCalendarEvents(newArr);
  }, [data, year]);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `https://my-tb-cors.herokuapp.com/https://transblueaccount-fns.azurewebsites.net/api/getAll?containerId=calendar&databaseId=employees`
      );

      let vacationAndWfh = response.data;
      console.log(
        "vacationAndWfh",
        vacationAndWfh
          .filter((e) => e.type === "Birthday / Anniversary")
          .map((e) => ({
            displayName: e.employee,
            birthday: e.birthday,
          }))
      );
      let allEntries = [...employees, ...vacationAndWfh];
      setUnfilteredData(allEntries);
      setData(allEntries);
    };
    getData();
  }, [employees]);

  const addEntry = (entry) => {
    setData([...data, entry]);
  };

  const updateEventsArray = (entry) => {
    let arr = data.map((event) => {
      if (event.id === entry.id) {
        return entry;
      } else if (
        entry.azureId &&
        // event.azureId &&
        event.azureId === entry.azureId
      ) {
        return entry;
      }
      return event;
    });

    setData([...arr]);
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);

    e.target.value === "All"
      ? setData(unfilteredData)
      : setData(
          unfilteredData.filter((entry) => entry.type === e.target.value)
        );
  };

  const handleEdit = (eventInfo) => {
    //console.log(unfilteredData.filter(entry => entry.id === eventInfo?.event?.id || (entry.azureId && entry.id === eventInfo.event?.azureId)))

    if (admin) {
      let event = unfilteredData.filter(
        (entry) => entry.id === eventInfo.event?.extendedProps?.azureId
      );
      console.log("event", event);

      if (event.length > 0) {
        setActiveEntry(event[0]);
        setEditOpen(true);
      }
    }
  };

  const removeEntryFromArray = (entry) => {
    setData(data.filter((event) => event.id !== entry.id));
  };

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  // const getBirthdays = () => {
  //   axios
  //     .get(
  //       `https://my-tb-cors.herokuapp.com/https://transblueaccount-fns.azurewebsites.net/api/getAll?containerId=calendar&databaseId=employees`
  //     )
  //     .then((res) => {
  //       let arr = res.data
  //         .filter((e) => e.birthday.length > 0)
  //         .map((e) => ({
  //           ["First Name"]: e.employee?.split(" ")[0],
  //           ["Last Name"]: e.employee?.split(" ")[1],
  //           Birthday: `${new Date(e.birthday)?.getMonth() + 1} / ${new Date(
  //             e.birthday
  //           )?.getDate()}`,
  //           Anniversary: `${
  //             new Date(e.anniversary)?.getMonth() + 1
  //           } / ${new Date(e.anniversary)?.getFullYear()}`,
  //         }));

  //       console.log(arr);

  //       let worksheet = XLSX.utils.json_to_sheet(arr);
  //       let workbook = XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //       XLSX.writeFile(workbook, "Birthdays.xlsx");
  //     });
  // };

  return (
    <EventsContext.Provider value={{ calendarEvents }}>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          {/* Modal for new calendar entry */}
          <CalendarEntry setOpen={setOpen} addEntry={addEntry} />
        </Box>
      </Modal>

      <Modal
        open={editOpen}
        onClose={() => {
          setEditOpen(false);
          setActiveEntry({});
        }}
      >
        <Box sx={style}>
          {activeEntry?.["@odata.type"] ? (
            <EditBirthdayModal
              employee={activeEntry}
              setEditOpen={setEditOpen}
              updateEventsArray={updateEventsArray}
            />
          ) : (
            <EditCalendarEntry
              setEditOpen={setEditOpen}
              initialValues={activeEntry}
              updateEventsArray={updateEventsArray}
              removeEntryFromArray={removeEntryFromArray}
            />
          )}
        </Box>
      </Modal>

      <Box sx={{ display: "flex", pb: 2 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Select
            value={filter}
            onChange={handleFilter}
            size="small"
            fullWidth
            sx={{ bgcolor: "white", maxWidth: "250px" }}
          >
            <MenuItem value="All">All Events</MenuItem>
            {types.map((type) => (
              <MenuItem value={type} key={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {admin && (
          <Box>
            {/* <Button
              onClick={getBirthdays}
              variant="outlined"
              sx={{ fontSize: "12px", mr: 1, textTransform: "capitalize" }}
            >
              Export
            </Button> */}
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              sx={{ fontSize: "12px", textTransform: "capitalize" }}
            >
              Add Event
            </Button>
          </Box>
        )}
      </Box>
      <Paper elevation={0} sx={{ p: 2 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={9}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              events={calendarEvents}
              weekends
              eventClick={handleEdit}
              datesSet={updateMonth}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ width: "100%", pl: 2 }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{ display: { xs: "block", md: "none" } }}
                >
                  <Tab
                    sx={{
                      fontSize: "12px",
                    }}
                    label="Calendar"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{ fontSize: { xs: "12px", md: "auto" } }}
                    label="Notifications"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{ fontSize: { xs: "12px", md: "auto" } }}
                    label="Search"
                    {...a11yProps(1)}
                  />
                </Tabs>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  <Tab
                    sx={{ fontSize: { xs: "12px", md: "auto" } }}
                    label="Notifications"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{ fontSize: { xs: "12px", md: "auto" } }}
                    label="Search"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" }, mt: 3 }}>
                <CustomTabPanel value={value} index={0}>
                  <FullCalendar
                    height="75vh"
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    events={calendarEvents}
                    weekends
                    eventClick={handleEdit}
                    datesSet={updateMonth}
                    headerToolbar={{
                      end: "",
                      start: "",
                      center: "title",
                    }}
                    footerToolbar={{
                      end: "today prev, next",
                      start: "",
                      center: "",
                    }}
                    dateClick={(e) => console.log(e)}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Notifications data={data} month={month} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <Search />
                </CustomTabPanel>
              </Box>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <CustomTabPanel value={value} index={0}>
                  <Notifications data={data} month={month} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Search />
                </CustomTabPanel>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </EventsContext.Provider>
  );
}

export default NewCalendar;
